.u_position_fixed {
  position: fixed;
}
.u_position_absolute {
  position: absolute;
}
.u_position_relative {
  position: relative;
}
.u_overflow_hidden {
  overflow: hidden;
}

.u_clear_both {
  clear: both;
}
.u_float_left {
  float: left;
}
.u_float_right {
  float: right;
}

.u_text_center {
  text-align: center;
}
.u_text_right {
  text-align: right;
}
.u_text_left {
  text-align: left;
}

.u_text_transform_uppercase {
  text-transform: uppercase;
}
.u_text_transform_capitalize {
  text-transform: capitalize;
}
.u_text_decoration {
  text-decoration: none;
}
.u_text_decoration_underline {
  text-decoration: underline;
}

.u_font_weight_bold {
  font-weight: bold;
}

.u_margin_auto {
  margin: 0 auto;
}
.u_margin_bottom_0 {
  margin-bottom: 0;
}
.u_opacity_0 {
  opacity: 0;
}

.u_display_block {
  display: block;
}
.u_display_inline_block {
  display: inline-block;
}

.u_align_vertical_top {
  vertical-align: top;
}
.u_align_vertical_middle {
  vertical-align: middle;
}

.u_background_none {
  background: none;
}
.u_border_none {
  border: none;
}
.u_display_none {
  display: none;
}
.u_list_style_none {
  list-style-type: none;
}
.u_mobile_display_block {
  display: none;
}

.u_margin_1rem_top_bot {
  margin: 1rem 0;
}
.u_margin_2rem_top_bot {
  margin: 2rem 0;
}
.u_margin_3rem_top_bot {
  margin: 3rem 0;
}
.u_margin_1rem_rgt {
  margin-right: 1rem;
}
.u_mar_2rem_lt {
  margin-left: 2rem;
}

.u_cursor_pointer {
  cursor: pointer;
}

.u_cursor_not_allowed {
  cursor: not-allowed;
}

.u_bor_rad_0 {
  border-radius: 0;
}

.u_max_width_height {
  max-width: 100%;
  max-height: 100%;
}

.u_width_height {
  width: 100%;
  height: 100%;
}

.u_width{
  width: 100% !important;
}

.u_display_flex {
  display: flex;
}

.u_display_only_des {
  display: block;
}

.u_display_only_mob {
  display: none;
}

.u_pad_3rem_r {
  padding: 0 3rem 0 0;
}

.u_mar_2rem_t {
  padding: 2rem 0 0 0;
}

.u_mar_2rem_not_b_des {
  margin: 2rem 2rem 0 2rem;
}

.u_btn_grey {
  &:disabled {
    background-color: $color_white_three;
  }
}

.u_bor_rad_top_6px {
  border-radius: 6px 6px 0 0 !important;
}

.u_bor_rad_6px {
  border-radius: 6px;
}

.u_2rem_pad_top {
  padding: 2rem 0 0 0;
}

@media only screen and (max-width: 1023px) {
  .u_display_only_des {
    display: none;
  }

  .u_display_only_mob {
    display: block;
  }

  .u_mob_pad_bot_0 {
    padding-bottom: 0 !important;
  }

  .u_mar_2rem_not_b_des {
    margin: 0;
  }
}
