/** colors */
$color_white: #ffffff;
$color_white_one: #fbfaf8;
$color_white_two: #f8f6f3;
$color_white_three: #dcdcdc;

$color_black: #000000;
$color_black_one: #1f1f1f;
$color_black_two: #323231;
$color_black_rgba_one: rgba(0, 0, 0, 0.15);
$color_black_rgba_two: rgba(0, 0, 0, 0.5);
$color_black_rgba_three: rgba(0, 0, 0, 0.3);
$color_black_rgba_four: rgba(0, 0, 0, 0.08);
$color_black_rgba_five: rgba(0, 0, 0, 0.4);
$color_black_rgba_six: rgba(0, 0, 0, 0.05);
$color_black_rgba_seven: rgba(0, 0, 0, 0.07);

$color_orange_primary: #ee7700;
$color_orange_rgba_one: rgba(238, 119, 0, 0.1);
$color_orange_rgba_three: rgba(238, 119, 0, 0.3);

$color_maroon_primary: #af0c2a;

$color_red_primary: #e02726;
$color_red_one: #e2231a;
$color_brown_three: #cd853f;
$color_brown_four: #f5ebdc;
$color_brown_primary: #6f3c2f;
$color_brown_two: #803527;
$color_brown_five: #512314;

$color_grey: #616161;
$color_greyish_brown: #4e4e4e;
$color_grey_two: #969696;

$color_warm_grey: #797979;
$color_warm_grey_one: #7d7d7d;
$color_warm_grey_two: #8c8c8c;
$color_warm_grey_three: #979797;
$color_warm_grey_four: #707070;

$color_light_grey: #f2f2f0;
$color_light_grey_one: #eff1ee;
$color_light_grey_two: #f4f5f5;
$color_whitesmoke: #f5f5f5;
$color_light_grey_three: #c4c4c4;

$color_fire_brick: #be5a09;
$color_brick_orange: #d03710;
$color_red_brick: #d72300;

$color_restaurant_primary: #6f3c2f;
$color_restaurant_rgba_one: rgba(111, 60, 47, 0.1);

$color_dark-lime-green: #71ba00;
$color_dark-lime-green_rgba: rgba(113, 186, 0, 0.1);
$color_dark_green: #1a8738;
$color_dark_green_1: #188437;

$color_pink: #efd9d4;

$color_yellow_orange: #f7a800;
$color_pale_yellow: #f6ecac;

$color_blue: #428bca;

//font size
$font_size_8: 0.8rem;
$font_size_10: 1rem;
$font_size_11: 1.1rem;
$font_size_12: 1.2rem;
$font_size_13: 1.3rem;
$font_size_14: 1.4rem;
$font_size_15: 1.5rem;
$font_size_16: 1.6rem;
$font_size_18: 1.8rem;
$font_size_20: 2rem;
$font_size_24: 2.4rem;
$font_size_28: 2.8rem;
$font_size_30: 3rem;
$font_size_32: 3.2rem;
$font_size_40: 4rem;
$font_size_42: 4.2rem;
$font_size_45: 4.5rem;
$font_size_50: 5rem;
$font_size_60: 6rem;
$font_size_70: 7rem;

/** fonts */
$font_block_berthold_textured_filename: 'BlockBertholdTextured.woff';
$font_DIN_next_LT_Pro_filename: 'DINNextLTPro-Regular.woff';
$font_DIN_next_LT_Pro_Bold_filename: 'DINNextLTPro-Bold.woff';
$font_FSSammy_filename: 'FSSammy-Regular.woff';
$font_Flame_filename: 'Flame-Regular.ttf';
$font_Flame_Bold_filename: 'Flame-Bold.ttf';
$font_Flame_Sans_filename: 'FlameSans-Regular.ttf';
$font_ROBOTO_Bold_filename: 'Roboto-Bold.ttf';
$font_PingFangSC_Regular_filename: 'PingFang SC Regular.woff';

$font_block_berthold_textured: 'Flame-Regular';
$font_DIN_next_LT_Pro: 'FlameSans-Regular';
$font_DIN_next_LT_Pro_Bold: 'Flame-Regular';
$font_Flame: 'Flame-Regular';
$font_Flame_Bold: 'Flame-Bold';
$font_Flame_Sans: 'FlameSans-Regular';
$font_FSSammy: 'FSSammy-Regular';
$font_ROBOTO_Bold: 'Roboto-Bold';
$font_PingFangSC_Regular: 'PingFangSC-Regular';

/** assets path */
$asset-font-path: '../assets/fonts/';
$asset-image-path: '../assets/images/';

$themes: (
  delivery: (
    color: $color_orange_primary
  ),
  restaurant: (
    color: $color_grey
  )
);
