@import '../../../stylesheets/variables.scss';
@import '../../../stylesheets/mixins.scss';
.license {
  @include text(
    $font_block_berthold_textured,
    $font_size_20,
    normal,
    normal,
    normal,
    normal,
    0.25px
  );
}

.fssaiBox {
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  width: 20%;
  height: 27%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem;
  position: relative;
  top: 22px;
  border-radius: 4%;
}

.fssaiBoxMob {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: left;
  position: absolute;
  top: 75px;
  left: 20px;
}

.pageWrapper {
  height: 43rem;
  background-color: rgb(248, 246, 243);
}

.fssaiContainer {
  margin: 0.5rem 0rem 32rem 1.5rem;
}

.fssai {
  height: 7rem;
  width: 13rem;
  margin: 0rem 0rem 0rem 0.5rem;
}

@media only screen and(max-width:1023px) {
  .pageWrapper {
    background-color: #f5ebdc;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    justify-content: column;
    align-items: center;
  }

  .fssai {
    margin: 0rem 0rem 0rem -0.5rem;
  }

  .fssaiBox {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
    text-align: left;
    position: absolute;
    top: 75px;
    left: 20px;
  }

  .fssaiContainer {
    margin: 0rem 0rem 0rem 0rem;
  }
  .license {
    @include text(
      $font_block_berthold_textured,
      $font_size_16,
      normal,
      normal,
      normal,
      normal,
      0.25px
    );
    margin-top: 10px;
    font-size: 16px;
  }
}
