@import 'stylesheets/variables.scss';

// font families
@font-face {
  font-family: $font_block_berthold_textured;
  src: url($asset-font-path+$font_block_berthold_textured_filename)
    format('opentype');
}

@font-face {
  font-family: $font_DIN_next_LT_Pro;
  src: url($asset-font-path+$font_DIN_next_LT_Pro_filename) format('opentype');
}

@font-face {
  font-family: $font_DIN_next_LT_Pro_Bold;
  src: url($asset-font-path+$font_DIN_next_LT_Pro_Bold_filename)
    format('opentype');
}

@font-face {
  font-family: $font_FSSammy;
  src: url($asset-font-path+$font_FSSammy_filename) format('truetype');
}

@font-face {
  font-family: $font_ROBOTO_Bold;
  src: url($asset-font-path+$font_ROBOTO_Bold_filename) format('truetype');
}

@font-face {
  font-family: $font_PingFangSC_Regular;
  src: url($asset-font-path+$font_PingFangSC_Regular_filename)
    format('truetype');
}

@font-face {
  font-family: $font_Flame;
  src: url($asset-font-path+$font_Flame_filename) format('truetype');
}

@font-face {
  font-family: $font_Flame_Sans;
  src: url($asset-font-path+$font_Flame_Sans_filename) format('truetype');
}

@font-face {
  font-family: $font_Flame_Bold;
  src: url($asset-font-path+$font_Flame_Bold_filename) format('truetype');
}
